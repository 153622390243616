import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = []

  connect() {
    this.checkStatus()
    this.startPolling()
  }

  disconnect() {
    clearTimeout(this.interval)
  }

  startPolling() {
    this.interval = setInterval(() => {
      this.checkStatus()
    }, 3000)
  }

  checkStatus() {
    let path = this.data.get('redirectPath')
    fetch(path, {
      method: 'GET',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json'
      }
    })
    .then(response => response.json())
    .then(data => {
      if (data.status == 'ok') {
        Turbo.visit(path)
      }
    })
    .catch(error => console.error('Error:', error))
  }
}
