import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = [ 'expandable', 'collapsible' ]

  connect() {
    this.toggle({ target: { value: 'reschedule' } })
  }

  toggle(event) {
    const selectedReason = event.target.value

    if (selectedReason === "cancellation") {
      this.expand()
    } else if (selectedReason === "reschedule") {
      this.collapse()
    }
  }

  expand() {
    this.expandableTarget.classList.remove("expandable-content--collapsed")
    this.collapsibleTarget.classList.add("expandable-content--collapsed")

    this.enableInputs(this.expandableTarget)
    this.disableInputs(this.collapsibleTarget)
  }

  collapse() {
    this.expandableTarget.classList.add("expandable-content--collapsed")
    this.collapsibleTarget.classList.remove("expandable-content--collapsed")

    this.disableInputs(this.expandableTarget)
    this.enableInputs(this.collapsibleTarget)
  }

  enableInputs(target) {
    target.querySelectorAll('select, input, textarea').forEach(item => item.disabled = false)
  }

  disableInputs(target) {
    target.querySelectorAll('select, input, textarea').forEach(item => item.disabled = true)
  }
}
