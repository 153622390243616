import { Controller } from '@hotwired/stimulus'
import "trix"
import "@rails/actiontext"

export default class extends Controller {

  get editor() {
    return this.element.editor
  }

  connect() {
    this.element.addEventListener("trix-initialize", this.trixInitialize.bind(this))
  }

  trixInitialize() {
    if (this.editor) {
      const embedDialog = `
        <div data-behaviour="embed-container">
          <div class="embed-loading-indicator">
            Inhalt wird geladen...
          </div>
          <div class="embed-url-wrapper">
            <span>Möchtest du den Inhalt dieser Seite einbinden?</span>
            <input class="button is-small" type="button" data-behaviour="embed-url" value="Ja, einbinden">
          </div>
        </div>`

      const toolbar = this.element.toolbarElement;
      toolbar.querySelector('[data-trix-dialog=href]').insertAdjacentHTML('beforeend', embedDialog)

      if (this.element.dataset.aiEnabled == "true") {
        const AIButton = '<button type="button" class="trix-button" data-trix-action="ai" title="Generate AI">AI</button>'
        toolbar.querySelector('.trix-button-group--file-tools').insertAdjacentHTML('beforeend', AIButton)
        this.AIButton = toolbar.querySelector('[data-trix-action="ai"]')
        this.AIButton.addEventListener('click', this.generateAI.bind(this))
      }

      this.hrefInput = toolbar.querySelector("[data-trix-dialog=href] [name='href']")
      this.embedContainer = toolbar.querySelector("[data-behaviour='embed-container']")
      this.embedButton = toolbar.querySelector('[data-behaviour="embed-url"]')
      this.embedLoadingIndicator = this.embedContainer.querySelector('.embed-loading-indicator')
      this.embedUrlWrapper = this.embedContainer.querySelector('.embed-url-wrapper')

      this.hrefInput.autocomplete = 'off'

      this.hrefInput.addEventListener("input", this.didInput.bind(this))
      this.hrefInput.addEventListener("focusin", this.didInput.bind(this))
      this.embedButton.addEventListener('click', this.embed.bind(this))
    }
  }

  didInput(event) {
    let value = event.target.value.trim()

    // Load patterns from server so we can dynamically update them
    if (this.patterns === undefined) {
      this.loadPatterns(value)

      // When patterns are loaded, we can just fetch the embed code
    } else if (this.match(value)) {
      this.fetch(value)

      // No embed code, just reset the form
    } else {
      this.reset()
    }
  }

  loadPatterns(value) {
    fetch("/admin/embeds/patterns.json", {
      method: "get",
      headers: {
        "Accept": "application/json",
        "X-CSRF-Token": document.querySelector("[name='csrf-token']")?.content
      }
    })
    .then(response => response.json())
    .then(response => {
      this.patterns = response.map(pattern => new RegExp(pattern.source, pattern.options))
      if (this.match(value)) {
        this.fetch(value)
      }
    })
  }

  // Checks if a url matches an embed code format
  match(value) {
    return this.patterns.some(regex => regex.test(value))
  }

  fetch(value) {
    this.embedUrlWrapper.style.display = 'none'
    this.embedLoadingIndicator.style.display = 'flex'

    Rails.ajax({
      url: `/admin/embeds?id=${encodeURIComponent(value)}`,
      type: 'post',
      error: this.reset.bind(this),
      success: this.showEmbed.bind(this)
    })
  }

  embed(event) {
    if (this.currentEmbed == null) { return }

    let attachment = new Trix.Attachment(this.currentEmbed)
    this.element.editor.insertAttachment(attachment)
    this.element.focus()
    this.hrefInput.value = ''
    this.reset()
  }

  showEmbed(embed) {
    this.currentEmbed = embed
    this.embedUrlWrapper.style.display = "flex"
    this.embedLoadingIndicator.style.display = "none"
  }

  reset() {
    this.embedUrlWrapper.style.display = "none"
    this.embedLoadingIndicator.style.display = "none"
    this.currentEmbed = null
  }

  async generateAI(event) {
    event.preventDefault()

    // Disable the Trix editor
    this.element.disabled = true

    // Save original button text and show loading state
    const button = this.AIButton
    const originalText = button.textContent
    button.textContent = "Generating Response..."
    button.disabled = true

    // Get current text from Trix editor
    const currentText = this.element.editor.getDocument().toString()
    const message_id = this.element.dataset.messageId

    try {
      // Make API request to your Rails endpoint
      const response = await fetch('/admin/ai/message', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'X-CSRF-Token': document.querySelector('[name="csrf-token"]').content
        },
        body: JSON.stringify({
          content: currentText,
          message_id: message_id
        })
      })

      const data = await response.json()

      // Insert the AI-generated content into the Trix editor
      this.element.editor.insertString(data.generated_content)
    } catch (error) {
      console.error('Error:', error)
    } finally {
      // Re-enable the Trix editor
      this.element.disabled = false

      // Reset button state
      button.textContent = originalText
      button.disabled = false
    }
  }
}
