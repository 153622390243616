import { Controller } from "@hotwired/stimulus"

import EmblaCarousel from 'embla-carousel'
import Autoplay from 'embla-carousel-autoplay'
import { addDotBtnsAndClickHandlers } from '../modules/embla_carousel_dot_button'

export default class extends Controller {

  static targets = ["testimonialContainer", "magazineContainer"]

  connect() {
    if (this.hasTestimonialContainerTarget) {
      this.testimonialsCarousel
    }

    if (this.hasMagazineContainerTarget) {
      this.magazineCarousel
    }
  }

  get magazineCarousel() {
    const emblaNode = this.magazineContainerTarget
    const viewportNode = emblaNode.querySelector('.embla__viewport')
    const dotsNode = emblaNode.querySelector('.embla__dots')

    const options = { loop: false, autoplay: true, slidesToScroll: 'auto' }
    const plugins = [Autoplay({ delay: 4000, stopOnMouseEnter: true })]

    const emblaApi = EmblaCarousel(viewportNode, options, plugins)

    const removeDotBtnsAndClickHandlers = addDotBtnsAndClickHandlers(
      emblaApi,
      dotsNode
    )
    emblaApi.on('destroy', removeDotBtnsAndClickHandlers)
  }

  get testimonialsCarousel() {
    const emblaNode = this.testimonialContainerTarget
    const viewportNode = emblaNode.querySelector('.embla__viewport')
    const dotsNode = emblaNode.querySelector('.embla__dots')

    const options = { loop: false, autoplay: true, slidesToScroll: 'auto' }
    const plugins = [Autoplay({ delay: 4000, stopOnMouseEnter: true })]

    const emblaApi = EmblaCarousel(viewportNode, options, plugins)

    const removeDotBtnsAndClickHandlers = addDotBtnsAndClickHandlers(
      emblaApi,
      dotsNode
    )
    emblaApi.on('destroy', removeDotBtnsAndClickHandlers)
  }
}
