import { Controller } from "@hotwired/stimulus"

export default class extends Controller {

  checkTodo(event) {
    var data = {
      checked: event.target.checked,
      list_name: event.target.dataset.checklist,
      list_id: event.target.dataset.checklistId
    }

    fetch(this.data.get('url'), {
      method: "PATCH",
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
        "X-CSRF-Token": document.querySelector("[name='csrf-token']").content
      },
      body: new URLSearchParams(data).toString()
    })
  }
}
