import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = [ "title", "subtitle", "image", "priceText", "serviceDropdown", "generalData", "info", "faqGroup", "companyCheckbox" ]

  connect() {
  }

  initialize() {
    if (this.serviceDropdownTarget.selectedIndex == 0) {
      this.noSelectionState()
    } else {
      this.stateForSelection(this.serviceDropdownTarget.selectedIndex)
    }
  }

  change() {
    if (this.serviceDropdownTarget.selectedIndex == 0) {
      this.noSelectionState()
    } else {
      this.stateForSelection(this.serviceDropdownTarget.selectedIndex)
    }
  }

  companyToggle(event) {
    const checkbox = event.target
    var index = this.serviceDropdownTarget.selectedIndex
    var sd = this.serviceDropdownTarget.options[index].dataset
    if (checkbox.checked) {
        this.setPriceText(sd.pricetextcompany)
      } else {
        this.setPriceText(sd.pricetextprivate)
    }
  }

  stateForSelection(serviceIndex) {
    var sd = this.serviceDropdownTarget.options[serviceIndex].dataset
    this.setTitle(sd.title)
    this.setSubtitle(sd.subtitle)
    this.setImage(sd.image)
    this.setFaqItem(sd.faqGroup)

    // Check if the company checkbox is checked
    if (this.companyCheckboxTarget.checked) {
      this.setPriceText(sd.pricetextcompany);
    } else {
      this.setPriceText(sd.pricetextprivate);
    }

    this.setInfoPlaceholder(sd.info)
    this.setURL(sd.slug)
  }

  noSelectionState() {
    this.setTitle(this.generalDataTarget.dataset.title)
    this.setSubtitle(this.generalDataTarget.dataset.subtitle)
    this.setImage(this.generalDataTarget.dataset.image)
    this.setFaqItem("general")
    this.setPriceText("")
    this.setInfoPlaceholder("")
    this.setURL(this.generalDataTarget.dataset.slug)
  }

  setFaqItem(item) {
    this.faqGroupTargets.forEach(function(e) { e.style.display = "none"; });
    var faq = this.faqGroupTargets.filter(function(x) { return x.getAttribute("value") == item })[0]
    faq.style.display = "block"
  }

  setTitle(title) {
    this.titleTarget.innerText = title
  }

  setSubtitle(subtitle) {
    this.subtitleTarget.innerHTML = subtitle
  }

  setImage(path) {
    this.imageTarget.src = path
  }

  setPriceText(text) {
    this.priceTextTarget.innerText = text
  }

  setInfoPlaceholder(text) {
    this.infoTarget.placeholder = text
  }

  setURL(slug) {
    window.history.pushState({},"", slug);
  }
}
