import "@hotwired/turbo-rails"
import "./controllers"
import * as ActionCable from '@rails/actioncable'

// Run Stimulus teardown before navigating to another page
document.addEventListener('turbo:before-visit', () => {
  Stimulus.controllers.forEach(controller => {
    if (typeof controller.teardown === 'function') {
      controller.teardown()
    }
  })
})
