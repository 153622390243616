import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["dataURL", "textField", "nameLabel"]

  testMessage(event) {
    event.preventDefault()
    let telegramId = this.textFieldTarget.value
    fetch(`${this.dataURLTarget.value}/sendMessage`, {
          method: "post",
          headers: {
            "Content-Type": "application/x-www-form-urlencoded",
            "X-CSRF-Token": document.querySelector("[name='csrf-token']").content
          },
          body: `chat_id=${telegramId}&text=Telegram setup successfull!`
        })
        .then(() => alert("Test message sent!"))
  }

  getId(event) {
    event.preventDefault()
    let textField = this.textFieldTarget
    let nameLabel = this.nameLabelTarget
    fetch(`${this.dataURLTarget.value}/getUpdates`, {
        method: "get",
        headers: {
          "Accept": "application/json",
          "X-CSRF-Token": document.querySelector("[name='csrf-token']").content
        }
      })
      .then(response => response.json())
      .then(data => {
        var id = data.result.pop().message.from.id
        var name = data.result.pop().message.from.first_name
        textField.value = id
        nameLabel.textContent = name
    })
  }
}
